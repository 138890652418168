import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const t = TrelloPowerUp.iframe()
    const projectId = properties.projectId
    const promise = $tpu.r.projects.destroy(projectId, t).then(() => {
      GLib.action.execute({
        "action": "http/delete",
        "url": $tpu.urls.project(projectId),
      }, component);
    })
    this.executePromise(promise, properties, component, false)
    // this.executeProjectUpdate(promise, properties, component, false)
  }
}
